.card-wrapper {
    margin: 60px 0;
}
.news-link {
    margin-top: 8px;
	text-align: center;
}
.news-link a {
    color: rgba(255, 255, 255, 0.80);
}
.news-title {
    font-size: 28px;
    margin-top: 27px;
    line-height: 36px;
    width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
    font-weight: 600;
}
.icon-wrapper{
	display: flex;
	align-items: center;
	justify-content: center;
}
.icon-wrapper img{
    width: 40px;
    height: 40px;
}
@media (max-width: 1609px) {
    .card-wrapper{
        padding-left: 12px !important;
    }
}
@media (max-width: 1280px) {
    .news-title {
        font-size: 28px;
        margin-top: 27px;
        line-height: 36px;
    }
}
@media (max-width: 768px) {
    .news-title {
        margin-top: 0;
        font-size: 22px;
        max-width: 90%;
    }
    .card-wrapper{
        display: flex;
        margin: 20px 0 !important;
        align-items: center;
    }
    .card-content {
        flex: 1;
    }
    .icon-wrapper img{
        width: 30px;
        height: 30px;
        margin-right: 16px;
    }
}



