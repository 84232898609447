.section-title-wrapper {
    display: flex;
    align-items: center;
    max-width: 1609px;
    width: 100%;
    margin: 50px 0;
}
.header-text {
    font-size: 48px;
    flex: 1;
}
.header-link a{
    font-size: 18px;
    color: rgba(255, 255, 255, 0.80);
}
@media (max-width: 1609px) {
    .section-title-wrapper {
        padding: 0 12px;
    }
}
@media (max-width: 1280px) {

}
@media (max-width: 768px) {
    .section-title-wrapper {
        margin: 25px 0;
    }
    .header-text {
        font-size: 28px;
    }
    .header-link a{
        font-size: 16px;
    }
}

