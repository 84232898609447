.article {
	color: #18181B;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: left;
	padding-top: 80px;
}

.article-bottom-label {
	width: 100%;
	max-width: 1609px;
	margin: 0 auto;
	margin-top: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-top: 1px solid #e0e0e0;
	position: relative;
}

.article-bottom-label-item {
	flex: 1;
	font-size: 24px;
	line-height: 60px;
	text-align: center;
	font-weight: 500;
}

.article-bottom-label-time {
	font-size: 24px;
	display: inline-block;
}

.md-content {
	text-align: left;
	padding-top: 80px;
	max-width: 70%;
	margin: 0 auto;
}
@media screen and (max-width: 1607px){
	.md-content {
		text-align: left;
		padding: 80px 16px 0 16px !important;
		max-width: 1607px;
		margin: 0 auto;
	}
}
.article-banner {
	height: 100vh;
	width: 100%;
	max-height: 1000px;
	min-height: 600px;
	/* background-image: url("../static/hero-bg/bg-2.png"); */
	background-color: var(--banner-back-color);
	background-position: center;
	background-size: 100% 100%;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 80px;
}

.article-title {
	width: 100%;
	max-width: 1604px;
	font-size: 56px;
	line-height: 83px;
	padding: 48px 0;
	display: flex;
	align-items: center;
}

.article-title-btn {
	font-size: 24px;
	margin-left: auto;
	text-decoration: underline;
	cursor: pointer;
}

.article-conter {
	width: 100%;
	max-width: 1604px;
	margin: 0 auto;
	font-size: 24px;
	text-align: left;
}

.article-conter-text {
	max-width: 953px;
	margin: 0 auto;
	padding: 0 70px;
	margin-bottom: 112px;
}

.article-conter-title {
	margin-top: 60px;
	padding: 20px 0;
	position: relative;
	font-size: 28px;
}

.article-conter-paragraph {
	margin-bottom: 30px;
}

.article-conter-title::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 130px;
	height: 1px;
	background: #18181B;
}

.article-conter-btn {
	color: #2d9e45;
	font-size: 26px;
	text-decoration: underline;
	cursor: pointer;
}

.article-tabulation {
	width: 100%;
	max-width: 1630px;
	margin-bottom: 137px;
}

.article-bottom-label {
	width: 100%;
	max-width: 1609px;
	margin-top: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-top: 1px solid #e0e0e0;
	position: relative;
}

.article-bottom-label-item {
	flex: 1;
	font-size: 24px;
	line-height: 60px;
	text-align: center;
}

.article-bottom-label-time {
	font-size: 24px;
	display: inline-block;
}
