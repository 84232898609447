.privacy {
	color: #18181B;
	padding-top: 169px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: left;
}

.privacy-top {
	width: 100%;
	height: 293px;
	font-size: 56px;
	background: #f1f1f1;
	display: flex;
	align-items: center;
}

.privacy-top span {
	width: 100%;
	max-width: 1604px;
	margin: 0 auto;
}

.privacy-conter {
	width: 100%;
	max-width: 1604px;
	margin: 0 auto;
	font-size: 24px;
	text-align: left;
}

.privacy-conter-text {
	max-width: 953px;
	padding: 40px 70px 96px 70px;
	margin: 0 auto;
	margin-bottom: 112px;
}

.privacy-top-title {
	font-weight: bold;
	margin-bottom: 40px;
}

.privacy-conter-text-title {
	font-size: 32px;
	margin-top: 80px;
	margin-bottom: 24px;
}

.privacy-conter-paragraph {
	/* width: 820px; */
	color: #3F3F46;
	line-height: 32px;
}

.privacy-ul {
	margin-bottom: 0;
}

.privacy-ul li{
	list-style-type: none;
	position: relative;
}

.privacy-ul li::after {
	content: '·';
	line-height: 36px;
	font-size: 45px;
	position: absolute;
	top: -2px;
	left: -25px;
}
