/* 黑色主题颜色 */
.head-dark-theme {
	--font-color: #fff;
	--background-color: #000;
}

/* 白色主题颜色 */
.head-light-theme {
	--font-color: #000;
	--background-color: #fff;
}

/* Safety页面主题颜色 */
.Safety {
	--font-color: #073F5E;
	--background-color: #fff;
}

/* Blog页面主题颜色 */
.Blog {
	--font-color: #43040D;
	--background-color: #fff;
	--banner-back-color: #ffdad9;
}

/* About页面主题颜色 */
.About {
	--font-color: #60127C;
	--background-color: #fff;
	--banner-back-color: #dedbff;
}

/* Article页面主题颜色 */
.Article {
	--font-color: #46400a;
	--background-color: #fff;
	--banner-back-color: #efffbc;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.logo{
  height: 28px;
}
.nav-pos {
  position: fixed !important;
  width: 100vw;
  height: 80px;
  top: 0;
  left: 0;
}
.nav-pos-bottom{
  position: relative !important;
  width: 100%;
}
.navbar-brand {
  padding-top: 0 !important;
}
.bg-transparent-nav{
  background-color: transparent !important;
  transition: background-color .3s ease;
}
.navbar-nav {
  margin-left: 6.5vw;
}
.bg-black-nav {
  background-color: var(--background-color) !important;
  transition: background-color .3s ease;
}
.nav-container{
  max-width: 1607px !important;
}
.txt-white:focus {
  outline: none;
}
.txt-white {
  color: var(--font-color) !important;
  outline: none !important;
  border: none !important;
}

.app-head-dropdown .dropdown-toggle {
	height: auto !important;
	padding: 0 !important;
	border: 0 !important;
	background: none !important;
	text-decoration: none !important;
}

.app-head-dropdown .dropdown-toggle::after {
	/* display: none !important; */
	position: absolute !important;
	top: 50%;
	right: 0;
	transform: translate(0, -50%);
	border-top-color: var(--font-color) !important;
}

.app-head-user-dropdown {
	margin-top: 5px !important;
	padding: 5px 0 !important;
}

.app-head-user-dropdown .dropdown-item {
	font-size: 15px !important;
	color: var(--font-color) !important;
}

.app-head-user {
	padding: 4px;
	border-radius: 400px;
	font-size: 14px;
	display: flex;
	align-items: center;
	color: var(--font-color) !important;
	padding-right: 15px;
	cursor: pointer;
}

.app-head-user-img {
	width: 36px;
	height: 36px;
	border-radius: 50%;
	background: #ddd;
	margin-right: 10px;
}

.app-head-user-icon {
	margin-left: 2px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.nav-link {
  color: var(--font-color) !important;
  font-size: 18px !important;
}

.App-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: white;
}

.App-link {
  color: #61dafb;
}
.dropdown-item {
  padding-left: .5rem !important;
  color: var(--font-color) !important;
  font-size: 18px;
}
.dropdown-nav{
  background-color: inherit !important;
}
.dropdown-menu {
  border-radius: 0 !important;
  background-color: inherit !important;
  border: none !important;
  padding-top: 0 !important;
}
.dropdown-item:focus, .dropdown-item:hover {
  background-color: inherit !important;
}
.dropdown-item:focus, .dropdown-item:hover, .nav-item:hover,.dropdown-toggle:hover {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 6px;
}
.nav-item:first-child {
  margin-left: 0 !important;
}
.nav-item {
  margin-left: 1.5rem;
}
.border-whites{
  border: 2px solid var(--font-color) !important;
  border-radius: 12px !important;
}


.search-modal .modal-dialog .modal-content{
  width: 100vw;
  height: 38.5vh;
  background-color: black;
  border-radius: 0;
  position: fixed;
  top: 0 !important;
  left: 0 !important;
}
.modal-header {
  border-bottom: none !important;
  height: 80px;
}
.close-search-btn:hover {
  text-decoration: underline;
  text-underline-offset: 6px;
}
.close-search-btn {
  cursor: pointer;
}
.modal-footer {
  border-top: 0 !important;
  height: 100px;
}
.search-input {
  outline: none !important;
  border: none !important;
}
.form-control::placeholder {
  color: #ffffff88 !important;
}
.form-control:focus {
  border-color: #FFFFFF !important;
  outline: none !important;
  box-shadow: none !important;
}
.form-control {
  outline: none !important;
  border-radius: 4px !important;
  border: 1px solid #FFFFFF44 !important;
  color: white;
  background-color: transparent !important;
}
#button-search:hover {
  background-color: white;
  color: black;
}
#button-search{
  border-width: 1px;
  border-color: white;
  margin-left: 16px;
  border-radius: 4px;
  padding: 0 80px !important;
  background-color: black;
  color: white;
}
@media screen and (max-width: 992px) {
  .nav-item {
    margin-left: 0;
  }
  .nav-link {
    text-align: right;
    margin-right: 24px;
  }
  .dropdown-item{
    text-align: right !important;
    padding-right: 36px !important;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
