/* 白天主题 */
.light-theme {
    --theme-color: #eeeeee;
    --font-color: #000000; /* Add font color for light theme */
}

.transparent-theme {
    --theme-color: transparent;
    --font-color: #000000; /* Add font color for transparent theme */
}

/* 夜晚主题 */
.dark-theme {
    --theme-color: #18181B;
    --font-color: #ffffff; /* Add font color for dark theme */
}

.SiteBottomInfo {
	width: 100%;
	height: 260px;
	padding: 12px;
	margin-top: 25px;
	font-size: 18px;
	background-color: var(--theme-color);
	color: var(--font-color)
}

.SiteBottomInfo-conter {
	width: 100%;
	max-width: 1609px;
	border-top: 1px solid var(--theme-color);
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 29px;
}

.SiteBottomInfo-item {
	line-height: 30px;
}

.SiteBottomInfo-item-flex {
	display: flex;
	align-items: center;
	line-height: 21px;
	margin-bottom: 66px;
}

.back-top-btn {
	cursor: pointer;
	text-align: right;
}

.back-top-btn img {
	margin-left: 2px;
}

.back-btn-style a {
	cursor: pointer;
	color: var(--theme-color) !important
}
