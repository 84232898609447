.update-wrapper {
	width: 100%;
	max-width: 1709px;
	padding: 0 50px;
	margin-bottom: 72px;
	padding-bottom: 10px;
	position: relative;
}
.update-wrapper-scroll {
	overflow-x: auto;
	scroll-behavior: smooth;
}
.update-wrapper:hover .swithc_btn{
	opacity: 1 !important;
}
.swithc_btn {
	position: absolute;
	top: 45%;
	left: 0;
	transform: translate(0, -50%);
	width: 45px;
	height: 45px;
	opacity: 0.2;
	transition: all 0.5s;
	cursor: pointer;
}
.swithc_btn_right {
	left: auto;
	right: 0;
}
.list-row {
	flex-wrap: nowrap !important;
}
.list-row div{
	margin: 0;
}
.list-row div:last-child {
	margin-right: 0 !important;
}
.item-desc{
	margin: 12px 0 8px 0;
	line-height: 38px;
	font-size: 18px;
}
.item-img {
	width: 80%;
	aspect-ratio: 256/256;
	/*裁剪*/
	/* object-fit: cover; */
	/*等比缩放*/
	/* object-fit: contain; */
	height: auto;
	border-radius: 20px;
}
@media (max-width: 1609px) {
	.update-wrapper {
		/* padding: 0 12px; */
	}
}
@media (max-width: 991px) {
	/* .list-row div:nth-child(3) {
		margin-top: 24px;
	}
	.list-row div:nth-child(4) {
		margin-top: 24px;
	} */
	.item-desc,.item-date{
		margin: 12px 0 8px 0 !important;
		line-height: 38px;
		font-size: 18px;
	}
}
@media (max-width: 768px) {
	.list-row div{
		margin-right: 0;
	}
	/* .list-row div:nth-child(2) {
		margin-top: 36px;
	}
	.list-row div:nth-child(3) {
		margin-top: 36px;
	}
	.list-row div:nth-child(4) {
		margin-top: 36px;
	} */
	.item-desc,.item-date{
		margin: 12px 0 8px 0 !important;
		line-height: 38px;
		font-size: 18px;
	}
}
