.page {
    background-color: black;
}
.hero-sec {
    height: 100vh;
    width: 100%;
    max-height: 1000px;
    min-height: 600px;
    /* background-image: url("../static/hero-bg/bg-1.jpg"); */
		background: linear-gradient(to bottom, #2d2d2d, #000);
    background-position: center;
    background-size: 100% 100%;
    position: relative;
}

.hero-pos-show {
	position: absolute;
	top: 28%;
	left: 0;
	right: 0;
	width: 100%;
	max-width: 1607px;
	margin: 0 auto;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.hero-pos-show-btn {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: auto;
	margin-top: 56px;
}

.hero-pos-show-btn-item {
	background: #2a2a2a;
	border-radius: 48px;
	min-height: 56px;
	padding: 8px 40px;
	color: rgb(247, 247, 247);
	font-size: 16px;
	font-weight: 700;
	cursor: pointer;
	display: flex;
	align-items: center;
}

.hero-logo {
	width: 45%;
	margin-bottom: 106px;
}

.hero-pos-show-text {
	color: rgb(164, 164, 164);
	font-size: 1.4375rem;
	padding: 0 10px;
}

.hero-pos-bottom {
    position: absolute;
    bottom: 2.375rem;
    left: 0;
    right: 0;
    max-width: 1607px;
    margin: 0 auto;
    width: 1047px;
}
.hero-text {
    font-family: "Soehne", system-ui, -apple-system, Helvetica Neue, Arial, sans-serif;
    font-size: 5.625rem;
    line-height: 110%;
    letter-spacing: -.01em;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}
.hero-learn-btn {
    width: fit-content;
    padding: 6px 16px;
    background-color: transparent;
    color: #2D9E45;
    text-align: center;
    border: 1px solid #2D9E45;
    border-radius: 50px;
    margin-top: 70px;
}
.news-item-wrapper {
    width: 100% !important;
    max-width: 1609px;
    margin: 0 auto;
}

.item-new-class {
	width: 100% !important;
	margin: 0 auto;
	max-width: 1400px;
	margin: -50px auto;
	position: relative;
}

.item-new-class-image {
	width: 100% !important;
	opacity: 0;
	transition: all 1s;
}

.positioning {
	position: absolute;
	top: 0;
	left: 0;
}

.row-fill {
    width: 100%;
}
.central-img{
    max-width: 75%;
    height: auto;
    border-radius: 20px;
    margin-bottom: 80px;
}
.col-space{
	height: 104px;
	width: 100%;
}


@media (max-width: 1609px) {
    .hero-pos-bottom {
        bottom: 3.375rem;
        max-width: 80%;
    }
    .central-img{
        width: calc(100vw - 24px);
        height: auto;
        border-radius: 20px;
        margin-bottom: 80px;
    }
    .hero-text {
        font-size: 4.2rem;
        line-height: 120%;
    }
}

@media (max-width: 1280px) {
    .hero-pos-bottom {
        bottom: 3.375rem;
        max-width: 80%;
    }
    .hero-text {
        font-size: 4.2rem;
        line-height: 120%;
    }
}
@media (max-width: 992px) {
	.hero-logo {
		width: 70%;
		margin-bottom: 56px;
	}

	.hero-pos-show-btn {
		flex-wrap: wrap;
	}

	.hero-pos-show-btn-item {
		width: 85%;
		padding: 8px 40px;
		font-size: 14px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
		margin-bottom: 25px;
	}
    .nav-item {
        width: fit-content;
        padding: 6px 20px !important;
    }
    .navbar-nav {
        align-items: end;
    }
    .navbar-collapse {
        background-color: black;
        padding: 20px;
        width: 100vw !important;
        margin: 0 !important;
    }
}
@media (max-width: 768px) {
    .hero-pos-bottom {
        bottom: 3.375rem;
        max-width: 80%;
    }

    .hero-text {
        font-size: 2.6rem;
        line-height: 115%;
        letter-spacing: -.02em;
    }
}
